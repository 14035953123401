import React from 'react'

function New() {
  return (
    <>
      <div className='terms'>
      <h1>Privacy Policy</h1>
    <br/>
    <h2>1. Introduction</h2>
    <p>Welcome to <strong>Stalwart Business Consortium LLP (SBCL)</strong>. We are committed to protecting your privacy and ensuring that your personal data is handled securely and responsibly.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information:</p>
    <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, business details, and other contact information.</li>
        <li><strong>Transaction Data:</strong> Details about payments, purchases, and services availed.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website and services, including IP address, browser type, and interaction with our platform.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use the information we collect to:</p>
    <ul>
        <li>Provide and improve our sales and distribution services.</li>
        <li>Process payments and manage business transactions.</li>
        <li>Communicate with you regarding services, offers, and updates.</li>
        <li>Ensure security, prevent fraud, and comply with legal obligations.</li>
    </ul>

    <h2>4. Data Protection and Security</h2>
    <p>We implement appropriate security measures to protect your personal data from unauthorized access, disclosure, or misuse. However, no method of data transmission over the internet is 100% secure.</p>

    <h2>5. Sharing Your Information</h2>
    <p>We do not sell or trade your personal information. We may share your data with:</p>
    <ul>
        <li>Business partners and service providers who assist in delivering our services.</li>
        <li>Regulatory authorities when required by law.</li>
        <li>Third parties in case of business transfers such as mergers or acquisitions.</li>
    </ul>

    <h2>6. Cookies and Tracking Technologies</h2>
    <p>We may use cookies and similar tracking technologies to enhance user experience and analyze website performance.</p>

    <h2>7. Your Rights and Choices</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of marketing communications.</li>
        <li>Request a copy of the data we hold about you.</li>
    </ul>

    <h2>8. Data Retention</h2>
    <p>We retain your personal information for as long as necessary to fulfill business purposes, comply with legal requirements, and resolve disputes.</p>

    <h2>9. Changes to This Policy</h2>
    <p>We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page with the updated effective date.</p>

      </div>

    </>
  )
}

export default New