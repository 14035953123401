import React from 'react'
import Header from '../../coponents/header/Header'

function TnC() {
  return (
    <>
       {/* <div>
        <img
          src={require("../../assets/home/aboutus.png")}
          alt=""
          style={{ width: "100%" }}
          className="banner-image"
        />
      </div> */}
      <div className='terms'>
      <h1>Terms and Conditions</h1>
 

    <h2>1. Introduction</h2>
    <p>Welcome to <strong>Stalwart Business Consortium LLP (SBCL)</strong>. By engaging with our services, you agree to abide by the following Terms and Conditions. These terms govern the relationship between <strong>SBCL</strong> and its clients, partners, and stakeholders.</p>

    <h2>2. Definitions</h2>
    <ul>
        <li><strong>SBCL</strong> refers to <strong>Stalwart Business Consortium LLP</strong>, a Stalwart World Company.</li>
        <li><strong>Client</strong> refers to any FMCG brand, manufacturer, distributor, wholesaler, or stockist availing of our services.</li>
        <li><strong>Services</strong> refer to <strong>Plug-N-Play Sales Implementation, Route to Market solutions, Retail Sales, and Distribution services</strong> across various trade channels.</li>
    </ul>

    <h2>3. Scope of Services</h2>
    <p>SBCL provides end-to-end sales and distribution solutions, including but not limited to:</p>
    <ul>
        <li><strong>Retail Sales & Market Solutions</strong> in General Trade, Modern Trade, HoReCa, and Marketplaces.</li>
        <li><strong>Sales & Distribution Network Expansion</strong> across 100+ locations in India.</li>
        <li><strong>Plug-N-Play Sales Infrastructure</strong> for FMCG (F&B & HPC) brands.</li>
    </ul>

    <h2>4. Client Responsibilities</h2>
    <ul>
        <li>Provide accurate and up-to-date product information.</li>
        <li>Ensure timely payment for services rendered as per agreed terms.</li>
        <li>Comply with all legal and regulatory requirements related to their products.</li>
        <li>Maintain transparency in transactions and business dealings.</li>
    </ul>

    <h2>5. Payment Terms</h2>
    <ul>
        <li>Payment terms shall be agreed upon before service commencement.</li>
        <li>Any delay in payments beyond the due date may attract penalties or service suspension.</li>
        <li>All payments must be made via approved payment methods specified by SBCL.</li>
    </ul>

    <h2>6. Confidentiality</h2>
    <p>Both parties agree to maintain the confidentiality of proprietary business information shared during the engagement.</p>

    <h2>7. Liability & Indemnification</h2>
    <ul>
        <li>SBCL shall not be liable for any indirect, incidental, or consequential losses incurred by the client.</li>
        <li>Clients agree to indemnify SBCL against any claims arising due to non-compliance with regulatory standards.</li>
    </ul>

    <h2>8. Termination of Services</h2>
    <ul>
        <li>Either party may terminate the agreement with a <strong>30-day prior written notice</strong>.</li>
        <li>SBCL reserves the right to terminate services immediately in case of non-compliance or breach of contract.</li>
    </ul>

    <h2>9. Dispute Resolution</h2>
    <ul>
        <li>Any disputes shall be resolved through mutual discussion.</li>
        <li>If unresolved, disputes shall be subject to arbitration under <strong>Indian Arbitration Laws</strong>.</li>
    </ul>

    <h2>10. Governing Law</h2>
    <p>These terms shall be governed by and interpreted in accordance with the <strong>laws of India</strong>.</p>

    <h2>11. Amendments</h2>
    <p>SBCL reserves the right to modify these terms at any time. Updated terms will be communicated accordingly.</p>

      </div>
    </>
  )
}

export default TnC